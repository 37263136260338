import { ThemeIcon, Text, Title, Container, SimpleGrid, rem } from '@mantine/core';
import { IconUser, IconMessage2,IconFile, IconSailboat } from '@tabler/icons-react';
import classes from '../styles/Features.module.css';
import { useMediaQuery } from '@mantine/hooks';

const MOCKDATA = [
  {
    icon: IconFile,
    title: 'Wide Range of Project Technologies',
    description:
      'Choose from Python Django, Python Flask, PHP, Android, and AI-integrated Django projects.',
  },
  {
    icon: IconSailboat,
    title: 'Innovative Idea Credit',
    description:
      'Earn 20% of project sales when someone buys the project you collaborated on',
  },
  {
    icon: IconUser,
    title: 'Easy Dealer Program',
    description:
      'Become a dealer by either paying an upfront fee or referring students or projects.',
  },
  {
    icon: IconMessage2,
    title: 'Personalized Support',
    description:
      'Our team is here to assist you at every step of your project journey.',
  },
  {
    icon: IconFile,
    title: 'Frequently Updated Projects',
    description:
      'Stay ahead with projects that are constantly updated with the latest technologies.',
  },
];

interface FeatureProps {
  icon: React.FC<any>;
  title: React.ReactNode;
  description: React.ReactNode;
}

export function Feature({ icon: Icon, title, description }: FeatureProps) {
  return (
    <div>
      <ThemeIcon variant="light" size={40} radius={40}>
        <Icon style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
      </ThemeIcon>
      <Text mt="sm" mb={2}>
        {title}
      </Text>
      <Text size="sm" c="dimmed" lh={1.6}>
        {description}
      </Text>
    </div>
  );
}

export function Features() {
    const features = MOCKDATA.map((feature, index) => <Feature {...feature} key={index} />);
    const isMobile = useMediaQuery("(max-width: 430px)");
    const isTablet = useMediaQuery("(min-width: 431px) and (max-width: 1200px)");
  
    // Determine the number of columns based on media queries
    let cols = 3; // Default to 3 columns for larger screens
  
    if (isMobile) {
      cols = 1; // 1 column for mobile screens
    } else if (isTablet) {
      cols = 2; // 2 columns for tablet screens
    }
    return (
      <Container className={classes.wrapper} style={{ padding: '5%' }}>
        <Title className={classes.title}>Features</Title>
  
        {/* <Container size={560} p={0}>
          <Text size="sm" className={classes.description}>
            Every once in a while, you’ll see a Golbat that’s missing some fangs. This happens when
            hunger drives it to try biting a Steel-type Pokémon.
          </Text>
        </Container> */}
  
        <SimpleGrid
          mt={20}
          cols={cols}
        //   spacing="xl"
        //   verticalSpacing="lg"
          className="custom-grid" // Apply the CSS class
        >
          {features}
        </SimpleGrid>
      </Container>
    );
  }