import React, { useState, useEffect } from "react";
import { Container, Text, Button } from "@mantine/core";
import { useSpring, animated } from "react-spring";

function Hero() {
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    setShowText(true);
  }, []);

  const sectionStyle = {
    backgroundImage: 'url("./media/hero31.png")',
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "90vh",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "2rem",
  };
  const subtitleStyle = {
    color: "white",
    marginBottom: "1rem",
    fontSize: "1.5rem", // Default font size for larger screens
  };

  const buttonStyle = {
    fontSize: "1rem", // Default font size for larger screens
  };

  const textAnimation = useSpring({
    opacity: showText ? 1 : 0,
    marginTop: showText ? 10 : 20,
    from: { opacity: 0, marginTop: 200 },
  });

  // Adjust styles for smaller screens (e.g., mobile devices) using rem units
  const isMobile = window.innerWidth <= 768;
  const isTinyMobile = window.innerWidth <= 460;

  if (isMobile) {
    subtitleStyle.fontSize = "1.2rem"; // Decrease subtitle font size for smaller screens
    buttonStyle.fontSize = "1.2rem"; // Decrease button font size for smaller screens
  }

  if (isTinyMobile) {
    subtitleStyle.fontSize = "1rem"; // Further decrease font size for tiny screens
    buttonStyle.fontSize = "1rem"; // Further decrease font size for tiny screens
  }

  return (
    <div style={sectionStyle}>
      <Container size="xs">
        <animated.div style={textAnimation}>
          <Text align="center" size="lg" weight={500} style={subtitleStyle}>
            Unlock Your Potential with Our Innovative Projects
          </Text>
          <Text align="center" size="lg" weight={500} style={subtitleStyle}>
            Transforming Ideas into Real-World Solutions
          </Text>
        </animated.div>
        <div style={{ textAlign: "center" }}>
          <Button size="md" style={buttonStyle}>
            Register Now
          </Button>
        </div>
      </Container>
    </div>
  );
}

export default Hero;
