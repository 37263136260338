import React from "react";
import { Route, Routes } from "react-router-dom";
import Hero from "./components/Home/Hero";
import { Footer } from "./components/Footer";
import Home from "./components/Main/Home";
import { HeaderMenuColored } from "./components/Header";



function App() {
  // Define the type for the links array
  const links = [
    { link: "/", label: "Home" },
    {
      link: "#", // Updated to use relative path
      label: "Rooms",
      links: [
        { link: "classic", label: "Classic" }, // Updated to use relative path
        { link: "superior", label: "Superior" }, // Updated to use relative path
      ],
    },
    { link: "/gallery", label: "Gallery" },
    { link: "/nearby", label: "Nearby" },
    { link: "/contact", label: "Contact" },
  ];

  const footerData = [
    {
      title: "Links Group 1",
      links: [
        { link: "classic", label: "Classic" }, // Updated to use relative path
        { link: "superior", label: "Superior" },
        // Add more links here...
      ],
    },
    {
      title: "Links Group 2",
      links: [
        { link: "/gallery", label: "Gallery" },
        { link: "/nearby", label: "Nearby" },
        { link: "/contact", label: "Contact" },
        // Add more links here...
      ],
    },
  ];

  return (
    <div className="App">
      {/* <HeaderMenu /> */}
      <HeaderMenuColored links={links} />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="#" element={<Home />} /> */}
      </Routes>
      <Footer data={footerData} />
    </div>
  );
}

export default App;
