import { Col, Container, Grid, createStyles } from "@mantine/core";
import React from "react";
import { Heading } from "../Main/Heading";
import { IconBorderRadius } from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  section1: {
    marginTop: 60,
  },
  para: {
    width: "60%", // Change width to 100% for responsiveness
    textAlign: "center",
    lineHeight: 1.5,
  },
  benefitCardsContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "row",
    gap: "20px",
    padding: "20px",
    marginBottom: 60,

    "@media (max-width: 540px)": {
      // Corrected media query syntax
      flexDirection: "column", // Stack the cards vertically
      alignItems: "center", // Center align the cards
    },
  },
  benefitHeadline: {
    fontSize: "1.2rem",
    color: "#333",
  },
  benefitDescription: {
    fontSize: "1rem",
    color: "#666",
  },
  sectionAboveCards: {
    backgroundColor: "#f0f0f0", // Background color for the section above cards
    padding: "20px",
  },
  benefitCard: {
    borderRadius: "5px",
    boxShadow:"10px",
    padding: "10px",
    height:300,
    textAlign: "center",
    transition: "transform 0.3s ease-in-out, 0.3s ease-in-out",
    "&:hover": {
      transform: "translateY(-25px)",
      boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.2)",
    },
    "@media (max-width: 540px)": {
      width: "100%", // Make the cards full width
      marginBottom: "20px",
    },
    // background: `linear-gradient(to bottom, #ffffff 50%, transparent 50%)`, // Gradient background
  },
}));

const benefitCardsData = [
  {
    headline: "Hands-On Learning",
    description:
      "Gain practical experience and enhance your skills by working on real projects in various technologies.",
    svgColor: "#1ec199", // Change this to your desired SVG color for the first card
  },
  {
    headline: "Earn While You Learn",
    description:
      "Become a dealer with us and earn a 50% commission on every project you bring in.",
    svgColor: "#8a5fbb", // Change this to your desired SVG color for the second card
  },
  {
    headline: "Showcase Your Work",
    description:
      "Collaborate on projects with us, and we'll feature your creations on our website, allowing you to earn recognition and royalties.",
    svgColor: "#58a3f2", // Change this to your desired SVG color for the third card
  },
];

export default function BenefitCards() {
  const { classes } = useStyles();

  return (
    <Container>
      <Grid>
        <Col>
          <div className={classes.section1}>
            <Heading text="Our Reviews" />
          </div>

          <div className={classes.benefitCardsContainer}>
            {benefitCardsData.map((card, index) => (
              <div
                className={classes.benefitCard}
                key={index}
                style={{
                  boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.2)",
                  borderRadius: "5px",
                  backgroundImage: `url("data:image/svg+xml,${encodeURIComponent('<svg xmlns="http://www.w3.org/2000/svg" viewBox="16 0 800 320"><path fill="' + card.svgColor + '" fill-opacity="1" d="M0,96L48,112C96,128,192,160,288,170.7C384,181,480,171,576,149.3C672,128,768,96,864,96C960,96,1056,128,1152,160C1248,192,1344,224,1392,240L1440,256L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>')}")`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <h3 className={classes.benefitHeadline}>{card.headline}</h3>
                <p className={classes.benefitDescription}>{card.description}</p>
                <img
                  src="./media/user1.png"
                  style={{ borderRadius: "50%", marginTop: -15 }}
                  width={70}
                  height={70}
                  alt="User"
                ></img>
              </div>
            ))}
          </div>
        </Col>
      </Grid>
    </Container>
  );
}
