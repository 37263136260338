import { Col, Container, Grid, createStyles } from "@mantine/core";
import React from "react";
import { Heading } from "../Main/Heading";
import { useMediaQuery } from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
  section1: {
    marginTop: 90,
  },
  para: {
    width: "90%", // Change width to 100% for responsiveness
    textAlign: "center",
  },
}));

export default function ProblemSolution() {
  const { classes } = useStyles();
  const isMobile = useMediaQuery("(max-width: 998px)");

  return (
    <Container style={{ backgroundColor: "#FFF" }}>
      <Grid>
        {isMobile ? (
          // On mobile screens, display content above the video
          <>
            <Col span={12}>
              <div className={classes.section1}>
                <Heading text="Empowering Students with Real-World Projects" />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <p className={classes.para}>
                    Many students struggle to find practical applications for their knowledge. At Secret Code, we understand this challenge and offer a solution.
                  </p>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div style={{ display: "flex", alignItems: "center", height: 400 }}>
                <div style={{ position: "relative", marginRight: "20px" }}>
                  <img
                    src="./media/office2.jpeg"
                    alt="Your Image"
                    style={{ width: "100%", maxWidth: "350px", height: "auto" }}
                  />
                  <video
                    src="./media/office.mp4"
                    controls
                    style={{
                      position: "absolute",
                      bottom: 100,
                      right: -50,
                      width: "100%", // Make the video responsive
                      height: "auto", // Make the video responsive
                      maxWidth: "300px", // Adjust the maximum video width
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      borderRadius: "5%",
                      marginBottom: -150,
                    }}
                  ></video>
                </div>
              </div>
            </Col>
          </>
        ) : (
          // On larger screens, display content side by side with the video
          <>
            <Col span={12} md={6}>
              <div style={{ display: "flex", alignItems: "center", height: 400 }}>
                <div style={{ position: "relative", marginRight: "20px" }}>
                  <img
                    src="./media/office2.jpeg"
                    alt="Your Image"
                    style={{ width: "100%", maxWidth: "350px", height: "auto" }}
                  />
                  <video
                    src="./media/office.mp4"
                    controls
                    style={{
                      position: "absolute",
                      bottom: 100,
                      right: -50,
                      width: "100%", // Make the video responsive
                      height: "auto", // Make the video responsive
                      maxWidth: "300px", // Adjust the maximum video width
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      borderRadius: "5%",
                      marginBottom: -150,
                    }}
                  ></video>
                </div>
              </div>
            </Col>
            <Col span={12} md={6} sx={{ marginTop: 10 }}>
              <div className={classes.section1}>
                <Heading text="Empowering Students with Real-World Projects" />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <p className={classes.para}>
                    Many students struggle to find practical applications for their knowledge. At Secret Code, we understand this challenge and offer a solution.
                  </p>
                </div>
              </div>
            </Col>
          </>
        )}
      </Grid>
    </Container>
  );
}
