import { Col, Container, Grid, createStyles, useMantineTheme } from "@mantine/core";
import { Heading } from "../Main/Heading";

const useStyles = createStyles((theme) => ({
  outerContainer: {
    background: "#fbfbfb",
    // padding: "10px 0px",
    paddingTop: 70,
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "400px",
    position: "relative",
  },
  col: {
    padding: 20,
    textAlign: "center",
  },

  quote: {
    fontSize: "18px",
    paddingBottom: "20px",
  },
  heading: {
    fontSize: "26px",
    paddingBottom: "20px",
  },
}));

const testimonials = [
  {
    heading: "Hands-On Learning",
    description: "Gain practical experience and enhance your skills by working on real projects in various technologies.",
  },
  {
    heading: "Earn While You Learn",
    description: "Become a dealer with us and earn a 50% commission on every project you bring in.",
  },
  {
    heading: "Showcase Your Work",
    description: "Collaborate on projects with us, and we'll feature your creations on our website, allowing you to earn recognition and royalties.",
  },
];

const Benefits = () => {
  const { classes } = useStyles();
  const theme = useMantineTheme();

  return (
    <div className={classes.outerContainer}>
      <Heading text="Our Benefits"></Heading>

      <Container size="xl" className={classes.container}>
        <Grid gutter={theme.spacing.xl} justify="center">
          {testimonials.map((testimonial, index) => (
            <Col key={index} span={12} md={6} lg={4} className={classes.col}>
              {/* <div className={classes.rainbow}></div> */}
              <h4 className={classes.heading}>{testimonial.heading}</h4>
              <p className={classes.quote}>{testimonial.description}</p>
            </Col>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default Benefits;
