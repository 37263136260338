import { Text } from "@mantine/core";

interface TextProps {
  text: string;
  color?: string;
}

export function Heading({ text, color = "black" }: TextProps) {
  return (
    <Text
      size="xl"
      style={{
        fontFamily: "Quicksand sans-serif",
        fontSize: 32,
        color: color,
        textAlign: "center",
        fontWeight: "bold",
        lineHeight: 1, // Adjust the line height (smaller values reduce the gap)
        marginBottom: 5, // Adjust the margin between the text and underline
      }}
    >
      {text}
    </Text>
  );
}