import Hero from '../Home/Hero'
import ProblemSolutionSection from '../Home/ProblemSolution'
import Benefits from '../Home/Benefits'
import Review from '../Home/Review'
import { FaqWithImage } from '../Home/FAQ'
import { Features } from '../Home/Features'

export default function Home() {
  return (
    <div>
      <Hero />
      <ProblemSolutionSection />
      <Benefits />
      <Features />
      {/* <TestimonialSection /> */}
      <FaqWithImage />
      <Review />
    </div>
  )
}
