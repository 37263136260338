import React from "react";
import { Image, Accordion, Grid, Container, Title } from "@mantine/core";
import classes from "../styles/FAQ.module.css";

const faq = [
  {
    question: "How do I become a dealer?",
    answer:
      "You can become a dealer by either paying an upfront fee of 30,000 Rs or referring 30 students or completing 6 projects with us",
  },
  {
    question: "What if I have a unique project idea?",
    answer:
      "We welcome unique ideas! Collaborate with us, and we'll help you turn your idea into a reality.",
  },
  {
    question: "How do I showcase my work on the website?",
    answer:
      "When you collaborate on a project with us, we'll feature it on our website, giving you the recognition you deserve.",
  },
];

export function FaqWithImage() {
  return (
    <div className={classes.wrapper}>
      <Container size="lg" style={{ paddingTop: 50, paddingBottom: 50 }}>
        <Grid id="faq-grid" gutter={50}>
          <Grid.Col span={12} sm={6}>
            <Image
              src="./media/hero2.png"
              alt="Frequently Asked Questions"
              width={350}
              height={400}
            />
          </Grid.Col>
          <Grid.Col span={12} sm={6}>
            <Title order={2} ta="left" className={classes.title}>
              Frequently Asked Questions
            </Title>

            <Accordion
              chevronPosition="right"
              defaultValue="reset-password"
              variant="separated"
            >
              {faq.map((item, index) => (
                <Accordion.Item
                  key={index}
                  className={classes.item}
                  value={`faq-${index}`}
                >
                  <Accordion.Control>{item.question}</Accordion.Control>
                  <Accordion.Panel>{item.answer}</Accordion.Panel>
                </Accordion.Item>
              ))}
            </Accordion>
          </Grid.Col>
        </Grid>
      </Container>
    </div>
  );
}
