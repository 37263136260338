import {
  createStyles,
  Header,
  Menu,
  Group,
  Center,
  Burger,
  Container,
  rem,
  Text,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconChevronDown } from "@tabler/icons-react";
import { useClickOutside } from "@mantine/hooks";
import {
  Link,
  useLocation,
} from "react-router-dom";
import { useState } from "react";

const useStyles = createStyles((theme) => ({
  header: {
    backgroundColor: "#000000",
    borderBottom: 0,
  },
  headerInner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%", // Full width for small screens
  },
  inner: {
    height: rem(56),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  burger: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 3,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color: "#FFF",
    fontSize: theme.fontSizes.md,
    fontWeight: 500,
    "&:hover": {
      // backgroundColor: "#f2f2f2",
      color: "blue",
      marginTop: "15px",
    },
  },

  activeLink: {
    color: "blue", // Change this to the desired color for the selected link
  },
  linkLabel: {
    marginRight: rem(5),
  },
  dropdownLinks: {
    display: "flex",
    flexDirection: "column",
    color: "red",
    marginTop: theme.spacing.xs,
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.gray[6]
        : theme.colors.dark[6],
    borderRadius: theme.radius.sm,
    boxShadow: theme.shadows.md,
    minWidth: 200,
    position: "absolute",
    // top: HEADER_HEIGHT + 10, // Adjust as needed
    right: 10, // Adjust as needed
  },

  dropdownLink: {
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    fontSize: rem(13),
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[6],
    textDecoration: "none",
    textAlign: "center",

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[1],
    },
  },
}));

interface LinkWithSublinks {
  link: string;
  label: string;
  links: { link: string; label: string }[];
}

interface LinkWithoutSublinks {
  link: string;
  label: string;
  links?: undefined;
}

type HeaderSearchProps = {
  links: (LinkWithSublinks | LinkWithoutSublinks)[];
};

export function HeaderMenuColored({ links }: HeaderSearchProps) {
  const location = useLocation();
  const currentPath = location.pathname;
  const { classes } = useStyles();

  const [open, setOpened] = useState(false);
  const [dropdown, setDropdown] = useState<HTMLDivElement | null>(null);
  const [control, setControl] = useState<HTMLDivElement | null>(null);

  useClickOutside(() => setOpened(false), null, [control, dropdown]);

  const renderMenuItems = (link: LinkWithSublinks, isSmallScreen: boolean) => {
    const menuItems = link.links?.map((item) => (
      <Menu.Item key={item.link}>
        <Link
          to={item.link}
          className={currentPath === item.link ? classes.activeLink : ""}
          style={{ textDecoration: "none", color: "black" }}
        >
          {item.label}
        </Link>
      </Menu.Item>
    ));

    return (
      <Menu
        key={link.label}
        trigger={isSmallScreen ? "click" : "hover"}
        transitionProps={{ exitDuration: 0 }}
      >
        <Menu.Target>
          <Link
            to={link.link}
            className={`${classes.link} ${
              currentPath === link.link ? classes.activeLink : ""
            }`}
          >
            <Center>
              <span className={classes.linkLabel}>{link.label}</span>
              {isSmallScreen && <IconChevronDown size="0.9rem" stroke={1.5} />}
            </Center>
          </Link> 
        </Menu.Target>
        <Menu.Dropdown onClick={() => setOpened(false)}>
          {menuItems}{" "}
        </Menu.Dropdown>
      </Menu>
    );
  };

  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  const items = links.map((link) => {
    return link.links ? (
      renderMenuItems(link, isSmallScreen)
    ) : (
      <Link
        key={link.label}
        to={link.link}
        className={`${classes.link} ${
          currentPath === link.link ? classes.activeLink : ""
        }`}
        onClick={() => setOpened(false)}
      >
        {link.label}
      </Link>
    );
  });


  const dropdownItems = links.map((link) => (
    <Text className={classes.dropdownLink} key={link.label}>
      <Link
        to={link.link}
        style={{ textDecoration: "none" }}
        onClick={() => setOpened(false)}
      >
        {link.label}
      </Link>
    </Text>
  ));

  const handleBurgerClick = () => {
    setOpened(!open);
  };
  return (
    <Header
      height={70}
      className={classes.header}
      style={{ position: "fixed", background: "#000000" }}
    >
      <Container>
        <div className={classes.inner}>
          <img
            src="./media/user1.png"
            alt="Your Logo"
            style={{ height: 40, width: 50, paddingTop: "1%" }}
          />
          <Group variant="link" spacing={20} className={classes.links}>
            {items}
          </Group>
          <div ref={setDropdown}>
          <Burger
            opened={open}
            onClick={handleBurgerClick}
            className={classes.burger}
            size="sm"
            color="#fff"
            // hidden={open === true}
          />
          </div>
        </div>
        {open && (
          <div ref={setControl} className={classes.dropdownLinks} style={{  width: isSmallScreen ? `${window.innerWidth}px` : "auto",alignItems:"center"}}>
            {isSmallScreen ? items : dropdownItems}
          </div>
        )}
      </Container>
    </Header>
  );
}